import { useEffect, useState } from 'react';


const Login = (props) => {
  return <div className="mx-auto px-20">
    <label className="text-white text-lg font-bold">Email</label><br />
    <input className="bg-neutral-400 mt-4 p-2 rounded-xl" type="text"></input> <br />
    <br />
    <label className="text-white text-lg pt-6 mb-2 font-bold">Password</label> <br />
    <input className="bg-neutral-400 mt-4 p-2 rounded-xl" type="password"></input> <br />
    <button className="mt-6 text-white text-xl bg-black px-12 py-2 rounded-xl	">Accedi</button>
  </div>
}

const News = (props) => {
  const [news, setNews] = useState([]);
  const [viewNews, setViewNews] = useState({});

  const getNews = async () => {
    fetch('https://api.outbrief.it/api/articolos?populate=*')
      .then(res => res.json())
      .then(data => { (!data.error) && setNews(data.data) })
  }

  const insertNews = async (news) => {
    fetch('https://api.outbrief.it/api/articolos', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(news)
    })
      .then(res => res.json())
      .then(data => { (!data.error) && getNews() })
  }

  const deleteNews = async (id) => {
    fetch(`https://api.outbrief.it/api/articolos/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then(data => { (!data.error) && getNews() })
  }

  const updateNews = async (news) => {
    fetch(`https://api.outbrief.it/api/articolos/${news.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(news)
    })
      .then(res => res.json())
      .then(data => { (!data.error) && getNews() })
  }

  useEffect(() => {
    getNews();
  }, [])

  return <div className="mx-auto px-4">
    {viewNews.attributes ? <div className='p-4'>
      <div className='text-white'>
        <button className="w-1/3 text-white text-xl mb-4 py-2 font-2xl rounded-xl text-left font-bold" onClick={() => setViewNews({})}>{"<"}</button>
        <img className='w-full' src={`https://api.outbrief.it${viewNews.attributes.cover_image.data.attributes.url}`}></img>
        <h1 className="mt-4 text-white text-xl font-bold">{viewNews.attributes.title}</h1>
        <p>{viewNews.attributes.body}</p>
      </div>
    </div> : <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
      {news?.map(n => <div className="bg-neutral-800 p-4 rounded-xl mt-4 mb-2 shadow-lg">
        <span className='rounded-xl text-gray-300 bg-neutral-700 py-1 px-2 mb-4'>{n.attributes.categorias.data[0].attributes.title}</span>
        <div className='mt-4' style={{ height: "150px", backgroundSize: "cover", backgroundImage: `url(https://api.outbrief.it${n.attributes.cover_image.data.attributes.url})` }} />
        <h1 className="text-white text-xl font-bold mt-4">{n.attributes.title}</h1>
        <p className="text-white text-lg mb-4">{n.attributes.body}</p>
        <div className='text-center'>
          <button className='bg-neutral-900 px-4 py-1 text-white rounded-lg mr-4' onClick={() => deleteNews(n.id)}>Cancella</button>
          <button className='bg-neutral-900 px-4 py-1 text-white rounded-lg mr-4' onClick={() => updateNews({ id: n.id, data: { status: !n.attributes.status } })} >{n.attributes.status ? "Disattiva" : "Attiva"}</button>
          <button className='bg-neutral-900 px-4 py-1 text-white rounded-lg mt-2 mr-4'>Modifica</button>
          <button className='bg-neutral-900 px-4 py-1 text-white rounded-lg mt-2 mr-4' onClick={() => setViewNews(n)}>Visualizza</button>
        </div>
      </div>)}
    </div>}
  </div>
}

const Category = (props) => {
  return <div className="mx-auto px-20">
    <h1 className="text-white text-lg font-bold">Categorie</h1>
  </div>
}

const Users = (props) => {
  return <div className="mx-auto px-20">
    <h1 className="text-white text-lg font-bold">Utenti</h1>
  </div>
}
const Menu = (props) => {
  return <div>
    <img style={{ width: "120px" }} src="logo_new.png" className="w-full mx-auto" />
    <div className="scrollmenu mb-4 mx-4 bg-neutral-900 shadow-lg	rounded-xl" style={{}}>
      <button onClick={() => { props.setPage(1) }} className={`${props.page == 1 && "bg-neutral-800"} rounded-xl inline text-white text-lg px-6 py-2`}>News</button>
      <button onClick={() => { props.setPage(2) }} className={`${props.page == 2 && "bg-neutral-800"} rounded-xl inline text-white text-lg px-6 py-2`}>Categorie</button>
      <button onClick={() => { props.setPage(3) }} className={`${props.page == 3 && "bg-neutral-800"} rounded-xl inline text-white text-lg px-6 py-2`}>Utenti</button>
    </div>
  </div>
}

function App() {
  const [page, setPage] = useState(1);
  return (
    <div style={{ minHeight: "100vh" }} className="bg-stone-900	full-h ">
      <div className="full-h container mx-auto">
        <Menu setPage={setPage} page={page} />
        {
          {
            0: <Login />,
            1: <News />,
            2: <Category />,
            3: <Users />
          }[page]
        }
      </div>
    </div>
  );
}

export default App;
